import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
    menuButton: {
        marginRight: "auto",
    },
    menuIcon: {
        fontSize: "2rem"
    },
    logo: {
        flexGrow: 1,
    }
}));

export default function MobileMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    let history = useHistory();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const HandleClose = (link) => {
        setAnchorEl(null);
        // if (link) {
            history.push(link);
        // }
        // history.push(link);
    };
    return (
        <>
            <div className={classes.logo}>
                {props.logo}
            </div>
            <IconButton className={classes.menuButton} edge="start" color="inherit" aria-label="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MenuIcon className={classes.menuIcon}/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={HandleClose}
            >
                {props.menuItems.map(item => (<MenuItem onClick={() => { HandleClose(item.link) }}>{item.display}</MenuItem>))}
            </Menu>
        </>);
}

