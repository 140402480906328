import React from 'react';
import Grid from '@material-ui/core/Grid'
export default function DealOfTheDay() {
    
    return (
        <Grid container alignItems="center" justify="center">
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ffamfunfireworks%2F&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=262074252331896"
                title="followOnFacebook"
                width="500"
                height="500"
                style={{ 
                    "border": "none", 
                    "overflow": "hidden",
                    "marginTop": "80px",
                    "marginBottom": "20px" }}
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
            </iframe>
        </Grid>
    )
}