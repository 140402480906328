import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import MenuContent from './navMenuContent.js';
import Toolbar from '@material-ui/core/Toolbar';
import navStyles from '../style/nav.module.scss';
import bigLogo from '../assets/ffflogo.png';
// import {useHistory} from 'react-router-dom';

export default function ButtonAppBar(props) {
  let logo = <a href="/">
    <img alt="Family Fun Fireworks logo" className={navStyles.logo}
      //   add icon sizes
      src={bigLogo}
    />
  </a>;
  let menuItems = props.pages.filter((page) => page.display !== undefined && page.display !== null);
  return (
    <AppBar position={props.position}>
      <Toolbar>
        <MenuContent logo={logo} menuItems={menuItems} />
      </Toolbar>
    </AppBar>
  );
}
