import React from "react";
import homeStyles from '../../style/home.module.scss';
import DealOfTheDay from './DealOfTheDay';
import ActionCatcher from './ActionCatcher';
import ContactBar from "./ContactBar";
export default function Home() {
    return (
        <div id="page" className={homeStyles.page}>
            <div id="landingBanner">
            <ActionCatcher />
            <ContactBar />
            <DealOfTheDay />
            </div>
        </div>
    );

}
