import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileMenu from './mobileMenu';
import { useHistory } from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
const useStyles = makeStyles(() => ({
    menuButton: {
        margin: "auto",
        "font-size": "3ex"
    },
}));

export default function NavMenuContent(props) {
    // eslint-disable-next-line
    const classes = useStyles();
    let history = useHistory();
    if (useMediaQuery('(max-width:680px)')) {
        return (<MobileMenu menuItems={props.menuItems} logo={props.logo} />);
    }
    else {
        const navigate = (link) => history.push(link);
        const menuItems = props.menuItems.map(item => (<MenuItem key={item.key} onClick={() => { navigate(item.link) }} className={classes.menuButton}>{item.display}</MenuItem>));
        let finalElement = [];
        let i;
        for (i = 0; i < menuItems.length; i++) {
            if (Math.floor(menuItems.length / i) === 2) {
                finalElement.push(props.logo);
            } else {
                finalElement.push(menuItems[i]);
            }
        };
        return (<>
            <Grid container direction="row" >
                {menuItems.slice(0, 3)}
            </Grid>
            {props.logo}
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                {menuItems.slice(3, 7)}
            </Grid>
        </>
        );
    }
}