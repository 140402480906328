import React from 'react';
import Firework from '../assets/firework.svg';

export default function Loading() {
    let textStyle = {
        "font-size": "45px",
        "text-align": "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#FFFFFF"
    }
    let text = <h1 style={textStyle}>Loading...</h1>
    let img = <img src={Firework} style={{ width: "100%", "margin-top": "-10px" }} alt="loading" />;
    return (<>{text}{img}</>);
}