import React from 'react';
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

export default function ContactBar() {
    return (
        <Container flexDirection="row">
            <Box width={1 / 2} bgcolor="secondary.main">
                <Typography>
                    
                </Typography>
            </Box>
            <Box width={1 / 2} bgcolor="primary.main">
                <Typography>

                </Typography>
            </Box>
        </Container >
    );
}