import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavBar from './nav.js';
import Home from './Home/Home.js';
import Footer from './footer.js';
import Loading from "../assets/loading";
import '../style/home.font.scss';
import '../style/normalize.css';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Over from './Order/Shared/Over.js';

const theme = createTheme({
    // palette: {
    //     primary: {
    //         main: '#ff0000',
    //     },
    //     secondary: {
    //         main: '#2979ff',
    //     },
    // },
    palette: {
        primary: {
            main: '#ff0000',
        },
        secondary: {
            main: '#3c3b6e',
        },
    },
    typography: {
        fontFamily: ['Oswald', "sans-serif"]
    }
});
//TODO try without lazy loading to compare speed
const About = lazy(() => import('./About/About.js'));
const Contact = lazy(() => import('./Contact/Contact.js'));
const Locations = lazy(() => import('./Locations/Locations.js'));
const Order = lazy(() => import('./Order/Order.js'));
// const Deals = lazy(()=>import('./Deals/Deals.js'));
const Safety = lazy(() => import('./Safety/Safety'));
const OrderBase = lazy(() => import('./Order/Shared/OrderBase.js'));
const FundsRaisedFor = lazy(() => import('./Fundraiser/FundsRaisedFor'));
const Fundraising = lazy(() => import('./Fundraiser/landing'));
class App extends React.Component {


    render() {
        let pages = [
            { key: 0, display: "LOCATIONS", link: "locations" },
            { key: 1, link: "order", display: "SPECIAL ORDERS", element: <Order /> },
            { key: 2, link: "OrderOnline", display: "ORDER ONLINE", element: <OrderBase type="sale" /> },
            { key: 6, link: "safety", display: "SAFETY", element: <Safety /> },
            { key: 3, link: "about", display: "ABOUT US", element: <About /> },
            { key: 7, link: "contact", display: "CONTACT US", element: <Contact /> },
            // { key: 5, link: "fundraisingFor/:participantId", element: <OrderBase type="fundraising" /> },
            { key: 5, link: "fundraisingFor/:participantId", element: <Over /> },
            { key: 9, link: "fundraisingFor/:participantId", element: <Over /> },
            // { key: 9, link: "order/:participantId", element: <OrderBase enableDirect={false} type="order" /> },
            { key: 8, link: "fundsRaisedFor/:participantId", element: <FundsRaisedFor /> },
            { key: 4, display: "FUNDRAISING", link: "fundraising", element: <Fundraising /> },
            // {key:3, link: "deals", display:"DEALS", element: <Deals />}
        ]
        return (
            <MuiThemeProvider theme={theme}>
                <Router>
                    <Suspense fallback={Loading}>
                        <NavBar position="sticky" pages={pages} />
                        <Switch>
                            <Route exact path="/">
                                <Home />

                                <Footer />
                            </Route>
                            <Route path="/locations">
                                <Locations />
                            </Route>
                            {pages.map(page => (<Route key={page.key} path={"/" + page.link}>{page.element}<Footer /></Route>))}
                        </Switch>

                    </Suspense>
                </Router>
            </MuiThemeProvider>
        );
    }
}




export default App;
