import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography, useMediaQuery } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from 'react-router-dom';

const Slogan = withStyles((theme) => ({
    root: {
        "font-family": "Permanent Marker, cursive",
        "-webkit-text-stroke-width": "1px",
        "-webkit-text-stroke-color": "black",
        "letter-spacing": "0.3rem",
        "text-align": "center",
        "margin": "0 10px",
        [theme.breakpoints.down('xs')]: {
            "font-size": "3rem",
            "letter-spacing": "0.1rem",
        },
        [theme.breakpoints.up('sm')]: {
            "font-size": "4rem",
            "letter-spacing": "0.2rem",
        },
        [theme.breakpoints.up('md')]: {
            "font-size": "5rem",
            "letter-spacing": "0.3rem",
        },
    }
}))(Typography);

const RedText = withStyles({
    root: {
        color: "red",
        // "margin-right": "0px"
    }
})(Slogan);

const GreenText = withStyles({
    root: {
        color: "green",
        // "margin-right": "0px",
    }
})(Slogan);

const BoomButton = withStyles((theme) => ({
    root: {
        "font-size": "large",
        [theme.breakpoints.up("sm")]: {
            "font-size": "x-large",
        },
        "margin-top": "20px",
    }
}))(Button);

const ButtonGridRow = withStyles({
    container: {
        "margin-top": "30px",
    }
})(Grid);

export default function ActionCatcher(props) {
    const matches = useMediaQuery('(max-width:600px)');

    return (
        <Grid name="actionCatcher" container direction="column" justifyContent="center" spacing={2} alignItems="center">
            <Grid container direction="column"
                justifyContent="center"
                alignItems="center">
                <Slogan variant={matches ? "h2" : "h1"} component="h2" style={{marginTop: "20px"}}>THE MOST</Slogan>
                <RedText variant={matches ? "h2" : "h1"} >BANG</RedText>
            </Grid>
            <Grid container direction="column"
                justifyContent="center"
                alignItems="center">
                <Slogan variant={matches ? "h2" : "h1"} component="h2">FOR YOUR</Slogan>
                <GreenText variant={matches ? "h2" : "h1"} color="green">BUCK!</GreenText>
            </Grid>
            <ButtonGridRow container direction="row"
                justifyContent="center"
                alignItems="center">
                {/* <Grid item width={1/3}></Grid> */}
                {/* <Grid container width={1/3}
                    justifyContent="space-evenly"
                    direction="row"
                    alignItems="center"> */}
                    <Grid item>
                        <BoomButton component={RouterLink} to="/locations" size="large" variant="contained" color="primary">Find a Location</BoomButton>
                    </Grid>
                {/* </Grid> */}
                {/* <Grid item width={1/3}></Grid> */}
            </ButtonGridRow>
        </Grid>
    )
}