import Box from "@material-ui/core/Box";
import React from "react";

export default function Footer(props) {
    if(props.customStyle){
        return (
            <>
                <Box bgcolor="primary.main" style={{...props.customStyle.small,  "width": "auto", "height": "10px"}}>
                </Box>
                <Box bgcolor="secondary.main" style={{...props.customStyle.big,  "width": "auto", "height": "50px" }}>
                </Box>
            </>
        );
    }else{
        return (
            <>
                <Box bgcolor="primary.main" style={{"width": "auto", "height": "10px"}}>
                </Box>
                <Box bgcolor="secondary.main" style={{"width": "auto", "height": "50px" }}>
                </Box>
            </>
        );
    }
    
}