import { Grid, Typography } from "@material-ui/core";
import React from 'react';

export default function Over() {
    return (
        <Grid container direction="row" alignItems="center">
            <Grid container direction="column" alignItems="center">
                <Typography variant="h1">
                    Fundraising is over for the 2023 season.
                </Typography>
            </Grid>
        </Grid>
    );
}